import VarsityMainImage from "../asset/mainBanner.webp"
import VarsityOneImage from "../asset/gym.webp"
import VarsityTwoImage from "../asset/canteen.webp"
import VarsityThreeImage from "../asset/Untitled design - 2024-05-31T015132.055.webp"
import VarsityFourImage from "../asset/room.webp"

import Two from "../asset/gallery/780_2351.webp"
import Three from "../asset/gallery/780_2353.webp"
import Four from "../asset/gallery/780_2424.webp"
import Five from "../asset/gallery/780_2460 (1).webp"
import Six from "../asset/gallery/780_2596.webp"
import Seven from "../asset/gallery/780_2757.webp"
import Eight from "../asset/gallery/780_2762.webp"
import Nine from "../asset/gallery/780_2803.webp"
import Ten from "../asset/gallery/780_2807.webp"
import Eleven from "../asset/gallery/780_2814.webp"
import Twelve from "../asset/gallery/780_2825.webp"
import Thirteen from "../asset/gallery/780_2831.webp"
import Fourteen from "../asset/gallery/780_2845.webp"
import Fifteen from "../asset/gallery/780_2854.webp"
import Sixteen from "../asset/gallery/780_2856.webp"
import Seventeen from "../asset/gallery/780_2860.webp"
import Eighteen from "../asset/gallery/780_2861.webp"
import Ninteen from "../asset/gallery/780_2862.webp"
import Twenty from "../asset/gallery/780_2870.webp"
import Twentyone from "../asset/gallery/780_2875 (1).webp"
import TwentyTwo from "../asset/gallery/780_2935.webp"
import TwentyFour from "../asset/gallery/780_2939 (1).webp"
import TwentyFive from "../asset/gallery/780_2962.webp"
import TwentySix from "../asset/gallery/780_2963.webp"
import TwentySeven from "../asset/gallery/780_2980.webp"
import TwentyEight from "../asset/gallery/780_2988.webp"
import TwentyNine from "../asset/gallery/780_3003.webp"
import Thirty from "../asset/gallery/780_3006.webp"
import ThirtyOne from "../asset/gallery/780_3041.webp"
import ThirtyTwo from "../asset/gallery/780_3042.webp"
import ThirtyThree from "../asset/gallery/780_3043.webp"
import ThirtyFour from "../asset/gallery/780_3044.webp"
import ThirtyFive from "../asset/gallery/780_3046.webp"
import ThirtySix from "../asset/gallery/780_3048.webp"
import ThirtySeven from "../asset/gallery/780_3053.webp"
import ThirtyEight from "../asset/gallery/780_3056.webp"
import ThirtyNine from "../asset/gallery/780_3057.webp"
import Forty from "../asset/gallery/780_3078.webp"
import FortyOne from "../asset/gallery/780_3082.webp"
import FortyTwo from "../asset/gallery/780_3094.webp"
import FortyThree from "../asset/gallery/780_3098.webp"
import FortyFour from "../asset/gallery/780_3107.webp"
import FortyFive from "../asset/gallery/780_3110.webp"
import ReviewOne from "../asset/reviewOne.mp4"
import EventOne from "../asset/event/event1.webp"
import EventTwo from "../asset/event/event2.webp"
import EventThree from "../asset/event/event3.webp"
import EventFour from "../asset/event/event4.webp"
import EventFive from "../asset/event/event5.webp"
import EventSix from "../asset/event/event6.webp"
import EventSeven from "../asset/event/event7.webp"
import EventEight from "../asset/event/event8.webp"
import EventNine from "../asset/event/event9.webp"
import EventTen from "../asset/event/event10.webp"
import EventEleven from "../asset/event/event11.webp"
import EventTwelve from "../asset/event/event12.webp"
import EventThirteen from "../asset/event/event13.webp"
import EventMainOne from "../asset/event/eventmainone.webp"
import EventMainThree from "../asset/event/eventmainthree.webp"
import EventVideoOne from "../asset/event/videoone.mp4"
import EventVideoTwo from "../asset/event/video2.mp4"
import EventVideoThree from "../asset/event/video3.mp4"
import EventVideoFour from "../asset/event/video4.mp4"
import EventVideoFive from "../asset/event/video5.mp4"
import EventVideoSix from "../asset/event/video6.mp4"
import EventVideoSeven from "../asset/event/video7.mp4"
import EventVideoEight from "../asset/event/video8.mp4"
export const galleryImages = [

      {
        src: VarsityOneImage,
        width: "100%",
        height: "100%",
      },
      {
        src: VarsityTwoImage,
        width: "100%",
        height: "100%",
      },
      {
        src: VarsityThreeImage,
        width: "100%",
        height: "100%",
      },
      {
        src: VarsityFourImage,
        width: "100%",
        height: "100%",
      },
    
      {
        src: Two,
        width: "100%",
        height: "100%",
      },
      {
        src:Three,
        width: "100%",
        height: "100%",
      },
      {
        src:Four,
        width: "100%",
        height: "100%",
      },
      {
        src: Five,
        width: "100%",
        height: "100%",
      },
      {
        src: Six,
        width: "100%",
        height: "100%",
      },
      {
        src: Seven,
        width: "100%",
        height: "100%",
      },
      {
        src: Eight,
        width: "100%",
        height: "100%",
      },
      {
        src: Nine,
        width: "100%",
        height: "100%",
      },
      {
        src: Ten,
        width: "100%",
        height: "100%",
      },
      {
        src: Eleven,
        width: "100%",
        height: "100%",
      },
      {
        src:Twelve,
        width: "100%",
        height: "100%",
      },
      {
        src: Thirteen,
        width: "100%",
        height: "100%",
      },
      {
        src: Fourteen,
        width: "100%",
        height: "100%",
      },
      {
        src: Fifteen,
        width: "100%",
        height: "100%",
      },
      {
        src: Sixteen,
        width: "100%",
        height: "100%",
      },
      {
        src: Seventeen,
        width: "100%",
        height: "100%",
      },
      {
        src: Eighteen,
        width: "100%",
        height: "100%",
      },
      {
        src: Ninteen,
        width: "100%",
        height: "100%",
      },
      {
        src: Twenty,
        width: "100%",
        height: "100%",
      },
      {
        src: Twentyone,
        width: "100%",
        height: "100%",
      },
      {
        src: TwentyTwo,
        width: "100%",
        height: "100%",
      },
      {
        src: TwentyFour,
        width: "100%",
        height: "100%",
      },
      {
        src: TwentyFive,
        width: "100%",
        height: "100%",
      },
      {
        src: TwentyFive,
        width: "100%",
        height: "100%",
      },
      {
        src: TwentySix,
        width: "100%",
        height: "100%",
      },
      {
        src: TwentySeven,
        width: "100%",
        height: "100%",
      },
      {
        src: TwentyEight,
        width: "100%",
        height: "100%",
      },
      {
        src: TwentyNine,
        width: "100%",
        height: "100%",
      },
      {
        src: Thirty,
        width: "100%",
        height: "100%",
      },
      {
        src: ThirtyOne,
        width: "100%",
        height: "100%",
      },
      {
        src: ThirtyTwo,
        width: "100%",
        height: "100%",
      },
      {
        src: ThirtyThree,
        width: "100%",
        height: "100%",
      },
      {
        src: ThirtyFour,
        width: "100%",
        height: "100%",
      },
      {
        src: ThirtyFive,
        width: "100%",
        height: "100%",
      },
      {
        src: ThirtySix,
        width: "100%",
        height: "100%",
      },
      {
        src: ThirtySeven,
        width: "100%",
        height: "100%",
      },
      {
        src: ThirtyEight,
        width: "100%",
        height: "100%",
      },
      {
        src: ThirtyNine,
        width: "100%",
        height: "100%",
      },
      {
        src: Forty,
        width: "100%",
        height: "100%",
      },
      {
        src: FortyOne,
        width: "100%",
        height: "100%",
      },
      {
        src: FortyTwo,
        width: "100%",
        height: "100%",
      },
      {
        src: FortyThree,
        width: "100%",
        height: "100%",
      },
      {
        src: FortyFour,
        width: "100%",
        height: "100%",
      },
      {
        src: FortyFive,
        width: "100%",
        height: "100%",
      },
      
]

export const eventData = [
  {
    src: EventMainOne,
    width: "100%",
    height: "100%",
  },
  {
    src: EventMainThree,
    width: "100%",
    height: "100%",
  },
  {
    src: EventThirteen,
    width: "100%",
    height: "100%",
  },
  {
    type: "video",
    width: "100%",
    height: "100%",
    
    sources: [
      {
        src: EventVideoOne,
        type: "video/mp4",
      },
    ],
  },
  {
    src: EventOne,
    width: "100%",
    height: "100%",
  },
  {
    src: EventTwo,
    width: "100%",
    height: "100%",
  },
  {
    src: EventThree,
    width: "100%",
    height: "100%",
  },
  {
    type: "video",
    width: "100%",
    height: "100%",
    
    sources: [
      {
        src: EventVideoSeven,
        type: "video/mp4",
      },
    ],
  },
  {
    src: EventFour,
    width: "100%",
    height: "100%",
  },
  {
    type: "video",
    width: "100%",
    height: "100%",
    
    sources: [
      {
        src: EventVideoSix,
        type: "video/mp4",
      },
    ],
  },
  {
    src: EventFive,
    width: "100%",
    height: "100%",
  },
  {
    type: "video",
    width: "100%",
    height: "100%",
    
    sources: [
      {
        src: EventVideoTwo,
        type: "video/mp4",
      },
    ],
  },
  {
    src: EventSix,
    width: "100%",
    height: "100%",
  },
  {
    src: EventTwelve,
    width: "100%",
    height: "100%",
  },
  {
    src: EventSeven,
    width: "100%",
    height: "100%",
  },
  {
    type: "video",
    width: "100%",
    height: "100%",
    
    sources: [
      {
        src: EventVideoSix,
        type: "video/mp4",
      },
    ],
  },
  {
    src: EventEight,
    width: "100%",
    height: "100%",
  },
  {
    type: "video",
    width: "100%",
    height: "100%",
    
    sources: [
      {
        src: EventVideoThree,
        type: "video/mp4",
      },
    ],
  },
  {
    src: EventNine,
    width: "100%",
    height: "100%",
  },
  {
    type: "video",
    width: "100%",
    height: "100%",
    
    sources: [
      {
        src: EventVideoFour,
        type: "video/mp4",
      },
    ],
  },
  {
    src: EventTen,
    width: "100%",
    height: "100%",
  },
  {
    type: "video",
    width: "100%",
    height: "100%",
    
    sources: [
      {
        src: EventVideoFive,
        type: "video/mp4",
      },
    ],
  },
  {
    src: EventEleven,
    width: "100%",
    height: "100%",
  },
  {
    type: "video",
    width: "100%",
    height: "100%",
    
    sources: [
      {
        src: EventVideoEight,
        type: "video/mp4",
      },
    ],
  },
  
]